import { render, staticRenderFns } from "./Carrousel.vue?vue&type=template&id=20c3ddb6&scoped=true&"
import script from "./Carrousel.vue?vue&type=script&lang=js&"
export * from "./Carrousel.vue?vue&type=script&lang=js&"
import style0 from "./Carrousel.vue?vue&type=style&index=0&id=20c3ddb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c3ddb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VHover,VIcon,VResponsive,VSheet,VSkeletonLoader})
